var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reportListWrapper"},[_c('div',{staticClass:"SearchbarWrapper"},[_c('div',{staticClass:"searchbar"},[_c('Search',{attrs:{"placeholder":_vm.placeholder,"isAdvanceSearch":true,"filterConfig":_vm.searchConfig},on:{"queryString":_vm.queryString,"filterObject":_vm.advanceSearch,"clearFilter":_vm.advanceSearch}})],1),_c('div',{staticClass:"datepicker"},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.datePickerDate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.datePickerDate=$event},"update:return-value":function($event){_vm.datePickerDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date Range","readonly":"","outlined":""},on:{"click":_vm.dataSelector},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateDialog),callback:function ($$v) {_vm.dateDialog=$$v},expression:"dateDialog"}},[_c('v-date-picker',{attrs:{"range":"","scrollable":""},model:{value:(_vm.datePickerDate),callback:function ($$v) {_vm.datePickerDate=$$v},expression:"datePickerDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.resetDatePicker}},[_vm._v(" Reset ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.cancelDatePicker}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.submitDatePicker}},[_vm._v(" OK ")])],1)],1)],1)]),(_vm.totalCount === 0)?_c('div',{staticClass:"content-error-message"},[_vm._v(" Please add an Entry ")]):_c('div',{staticClass:"info-table"},[_c('v-data-table',{attrs:{"items-per-page":_vm.pageSize,"hide-default-footer":"","headers":_vm.headers,"expanded":_vm.expanded,"show-expand":"","item-key":"_id","items":_vm.dataList,"fixed-header":true},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.conducted_on_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.conducted_on_date ? _vm.getFormattedDate(item.conducted_on_date, "MMMM Do YYYY dddd") : "-")+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"expandable-section table-expanded-background",attrs:{"colspan":headers.length}},[_c('div',{staticClass:"expandable-section-title"},[_vm._v("Subject")]),_c('div',{staticClass:"expandable-section-content",domProps:{"innerHTML":_vm._s(item.subject)}},[_vm._v(" {{}} ")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isSalesTeamMember)?[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.openInputForm(true, item)}}},[_vm._v("EDIT")]),_c('v-list-item',{on:{"click":function($event){return _vm.deleteEntry(item)}}},[_vm._v("DELETE")])],1)],1)]:_vm._e()]}}],null,true)})],1),(_vm.isPaginationRequired)?_c('div',{staticClass:"paginationWrapper text-center"},[_c('v-pagination',{staticClass:"pagination-component",attrs:{"length":Math.ceil(_vm.fetchCount / _vm.pageSize),"total-visible":_vm.paginationTotalVisible},on:{"input":_vm.updatedPageNo},model:{value:(_vm.pageNo),callback:function ($$v) {_vm.pageNo=$$v},expression:"pageNo"}}),_c('div',{staticClass:"page-size-dropdown"},[_c('v-autocomplete',{attrs:{"items":_vm.pageSizeList,"auto-select-first":"","solo":"","dense":""},model:{value:(_vm.pageSize),callback:function ($$v) {_vm.pageSize=$$v},expression:"pageSize"}})],1)],1):_vm._e(),(_vm.isOnlySalesAgent)?[_c('UserForm',{attrs:{"name":_vm.name,"inputConfig":_vm.inputConfig,"keysToWatch":_vm.keysToWatch,"toggleForm":_vm.toggleForm,"formData":_vm.rowToEdit,"isEditMode":_vm.isEditMode},on:{"formOutput":_vm.formOutput,"closeForm":_vm.closeForm}})]:_vm._e(),_c('div',{staticClass:"floating-button"},[_c('v-speed-dial',{attrs:{"direction":"top","open-on-hover":true,"transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","dark":"","fab":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.fab)?_c('v-icon',[_vm._v(" mdi-arrow-down-drop-circle ")]):_c('v-icon',[_vm._v(" mdi-arrow-up-drop-circle ")])],1)]},proxy:true}]),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([(_vm.isOnlySalesAgent)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","dark":"","small":"","fab":""},on:{"click":function($event){return _vm.openInputForm()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}:null],null,true)},[_c('span',[_vm._v("Add Virtual Reach Entry")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"tertiary"},on:{"click":function($event){return _vm.downloadReach()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}])},[_c('span',[_vm._v("Download Virtual Reach")])])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }